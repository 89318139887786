import 'scroll-behavior-polyfill';

/*
let visual ;
// load
window.addEventListener( 'load', function(){
  createSwiper();
});
// resize
window.addEventListener( 'resize', function(){
  visual.destroy(false,true);
  createSwiper();
});
// config
const createSwiper = () =>{
  visual = new Swiper(".visual-slide", {
    loop: true,
    effect: 'fade',
    autoplay: true,
    breakpoints: {
      992: {
        autoplay: false,
      },
    },
  });
};
*/


//hover
/*(function(){
  const allTrigger = document.querySelectorAll('[data-slideto]')
  const mediaQuery = window.matchMedia('(min-width: 992px)');
  allTrigger.forEach( elm => {
    elm.addEventListener( 'mouseenter', () => {
      if( mediaQuery.matches ){
        const index = elm.getAttribute("data-slideto");
        visual.slideTo(index);
      }
    });
    elm.addEventListener( 'mouseleave', () => {
      if( mediaQuery.matches ){
        //const index = elm.getAttribute("data-slideto");
        //visual.slideTo( parseInt(index) + 1 );
        visual.slideTo(1);
      }
    });
  });
}());*/



// scrollBtn
var scrollbtn = new Swiper(".scrollbtn-slide", {
  slidesPerView: "auto",
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});



/*(function(){
  const panel = document.querySelector('.js-panel-close');
  if( !panel ){ return; }
  panel.addEventListener('click', function(){
    this.parentNode.classList.add('hide');
  });
}());



(function(){
  const header = document.querySelector('header');
  const initialClassName = 'position-initial';
  if( !header ){ return; }
  header.classList.add(initialClassName);
  window.addEventListener("scroll", () => {
    let scroll = window.pageYOffset;
    if(scroll === 0){
      header.classList.add(initialClassName);
    }else{
      header.classList.remove(initialClassName);
    }
  });
}());*/

/**
***  読み込まれているかの試し  */
/*alert('Hello World');
(function(){
  alert('Hello World');
}());*/
